const { domainMeta } = require('utils/domainMeta');

const REACT_APP_MONOLITH_BASE_URL = domainMeta.monolithOrigin;
const REACT_APP_GLOBAL_API_BASE_URL = `${domainMeta.monolithOrigin}/api_self_serve/v1`;
const REACT_APP_GLOBAL_API_BASE_URL_V2 = `${domainMeta.monolithOrigin}/api_self_serve/v2`;
const REACT_APP_LANDING_PAGE_URL = `${domainMeta.monolithOrigin}/users/sign_in`;
const REACT_APP_SIGN_OUT_REDIRECT_URL = `${domainMeta.monolithOrigin}/users/sign_in`;
module.exports = {
  REACT_APP_MONOLITH_BASE_URL,
  REACT_APP_GLOBAL_API_BASE_URL,
  REACT_APP_GLOBAL_API_BASE_URL_V2,
  REACT_APP_LANDING_PAGE_URL,
  REACT_APP_SIGN_OUT_REDIRECT_URL,
  IMAGE_TAG: process.env.IMAGE_TAG,
  NODE_ENV: process.env.NODE_ENV,
  REACT_APP_CHARGEBEE_SITE: process.env.REACT_APP_CHARGEBEE_SITE,
  REACT_APP_CLEARBIT_API_BASE_URL: process.env.REACT_APP_CLEARBIT_API_BASE_URL,
  REACT_APP_DATADOG_APPLICATION_ID:
    process.env.REACT_APP_DATADOG_APPLICATION_ID,
  REACT_APP_DATADOG_CLIENT_TOKEN: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
  REACT_APP_DATADOG_SAMPLE_RATE: process.env.REACT_APP_DATADOG_SAMPLE_RATE,
  REACT_APP_DATADOG_SITE: process.env.REACT_APP_DATADOG_SITE,
  REACT_APP_FRANCHISE_API_BASE_URL:
    process.env.REACT_APP_FRANCHISE_API_BASE_URL,
  REACT_APP_LOGGING_MICROSERVICE_AUTH_TOKEN:
    process.env.REACT_APP_LOGGING_MICROSERVICE_AUTH_TOKEN,
  REACT_APP_LOGGING_MICROSERVICE_URL:
    process.env.REACT_APP_LOGGING_MICROSERVICE_URL,
  REACT_APP_FAKER_SEED: process.env.REACT_APP_FAKER_SEED,
};
